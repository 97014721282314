import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';

import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import { searchScopes } from '@worklist-2/core/src/context/consts/searchScopes';
import { useFhirDataLoader } from '@worklist-2/core/src/hooks/useFhirDataLoader';
import { useConfig } from '@worklist-2/core/src/context/ConfigContext';
import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import Patient from '../models/Patient';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		background: 'transparent',
	},
});

export const Content = ({ title, subtitle, icon }) => (
	<Box sx={{ display: 'flex' }}>
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '40px',
				margin: '0 10px 0 0',
			}}
		>
			{icon}
		</Box>
		<Box>
			<Typography
				color="textSecondary"
				sx={{
					fontSize: '12px',
					lineHeight: '20px',
					letterSpacing: '0.25px',
					color: 'rgba(255, 255, 255, 0.6)',
					whiteSpace: 'nowrap',
				}}
				variant="body2"
			>
				{title}
			</Typography>
			<Typography
				sx={{
					fontWeight: 400,
					color: '#fff',
					fontSize: '14px',
					lineHeight: '16.41px',
					whiteSpace: 'nowrap',
				}}
				variant="subtitle1"
			>
				{subtitle}
			</Typography>
		</Box>
	</Box>
);

const ResendInviteButton = ({ closeResentBtn, showToastMessage }) => {
	const patient = usePatientStore(state => state.patient?.patient);
	const setPatient = usePatientStore(state => state.setPatient);
	const [isLoading, setIsLoading] = useState(false);

	const params = useParams();
	const { t } = useTranslation('patientInfo');
	const __config = useConfig();
	const patientLoader = useFhirDataLoader({ scope: searchScopes.patient });

	const handleClick = async () => {
		try {
			setIsLoading(true);

			const response = await axios.post(
				`${__config.data_sources.fhir}/Patient/ResendInvite?internalPatientIds=${params.patientId}`
			);

			if (!response?.status?.toString()?.startsWith(2)) {
				return null;
			}

			setIsLoading(false);
			showToastMessage(t('Invite sent'));
			closeResentBtn();

			// Refetch patient info after delay
			setTimeout(async () => {
				const resPatient = await patientLoader.load({ id: params.patientId }, true);

				if (resPatient.resourceType !== 'Patient') return;

				const patientClass = new Patient(resPatient);

				setPatient(patientClass);
			}, 2000);
		} catch (err) {
			console.error(err);
			setToastUtility(t('Failed to send invite!'));
			setIsLoading(false);
		}
	};

	const isBlumePatient = useMemo(
		() => _.find(patient?.extension, { url: fhirExtensionUrls.patient.blumePatient })?.valueBoolean,
		[patient]
	);
	const inviteSentTime = useMemo(
		() => _.find(patient?.extension, { url: fhirExtensionUrls.patient.inviteSentTime })?.valueString,
		[patient]
	);
	const inviteStatus = useMemo(
		() => _.find(patient?.extension, { url: fhirExtensionUrls.patient.inviteStatus })?.valueString?.toLowerCase(),
		[patient]
	);

	if (!patient) return null;

	const isActive = inviteStatus === 'active' || isBlumePatient;
	const isErrored = inviteStatus === 'invite error';
	const clientTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<CustomTooltip
				placement="bottom-start"
				title={
					<Box
						sx={{
							position: 'relative',
							width: '293px',
							height: '157px',
							boxSizing: 'border-box',
							borderRadius: '10px',
							background: 'rgba(59, 59, 59, 1)',
							overflow: 'hidden',
						}}
					>
						<Timeline sx={{ '& .MuiTimelineItem-root::before': { display: 'none' } }}>
							<TimelineItem>
								<TimelineSeparator>
									<CustomTimelineDot active isErrored={isErrored} sx={{ marginBottom: 0 }} />
									<CustomTimelineConnector active isErrored={isErrored} />
								</TimelineSeparator>
								<TimelineContent>
									<Content
										icon={
											<svg fill="none" height="24" viewBox="0 0 12 10" width="24">
												<path
													d="M0.75 9.66927V0.335938L11.8333 5.0026L0.75 9.66927ZM1.91667 7.91927L8.82917 5.0026L1.91667 2.08594V4.1276L5.41667 5.0026L1.91667 5.8776V7.91927Z"
													fill={isErrored ? '#B96877' : '#03DAC5'}
												/>
											</svg>
										}
										subtitle={isErrored ? 'Blume Invitation Error' : 'Blume Invitation Sent'}
										title={moment
											.utc(inviteSentTime)
											.tz(clientTimezone)
											.format('DD/MM/yyyy · hh:mm')}
									/>
								</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineSeparator sx={{ height: '35px' }}>
									<CustomTimelineConnector active={isActive} isErrored={isErrored} />
									<CustomTimelineDot active={isActive} isErrored={isErrored} sx={{ margin: 0 }} />
								</TimelineSeparator>
								<TimelineContent>
									<Content
										icon={
											<svg fill="none" height="24" viewBox="0 0 24 24" width="24">
												<mask
													height="24"
													id="mask0_3803_2210"
													maskUnits="userSpaceOnUse"
													style={{ maskType: 'alpha' }}
													width="24"
													x="0"
													y="0"
												>
													<rect fill="#D9D9D9" height="24" width="24" />
												</mask>
												<g mask="url(#mask0_3803_2210)">
													<path
														d="M5 23.6969C4.45 23.6969 3.97917 23.501 3.5875 23.1094C3.19583 22.7177 3 22.2469 3 21.6969V7.69688C3 7.14688 3.19583 6.67604 3.5875 6.28437C3.97917 5.89271 4.45 5.69688 5 5.69688H13.925L11.925 7.69688H5V21.6969H19V14.7469L21 12.7469V21.6969C21 22.2469 20.8042 22.7177 20.4125 23.1094C20.0208 23.501 19.55 23.6969 19 23.6969H5ZM16.175 6.27188L17.6 7.67188L11 14.2719V15.6969H12.4L19.025 9.07188L20.45 10.4719L13.25 17.6969H9V13.4469L16.175 6.27188ZM20.45 10.4719L16.175 6.27188L18.675 3.77188C19.075 3.37188 19.5542 3.17188 20.1125 3.17188C20.6708 3.17188 21.1417 3.37188 21.525 3.77188L22.925 5.19688C23.3083 5.58021 23.5 6.04688 23.5 6.59687C23.5 7.14687 23.3083 7.61354 22.925 7.99688L20.45 10.4719Z"
														fill={isActive ? '#03DAC5' : 'white'}
														fillOpacity={isActive ? '1' : '0.6'}
													/>
												</g>
											</svg>
										}
										subtitle={isActive ? 'Account Created' : 'Account Creation'}
										title="In progress:"
									/>
								</TimelineContent>
							</TimelineItem>
						</Timeline>
					</Box>
				}
			>
				<Button
					color="rsPrimary"
					disabled={isLoading}
					startIcon={
						isLoading ? null : (
							<svg fill="none" height="10" viewBox="0 0 12 10" width="12">
								<path
									d="M0.75 9.66927V0.335938L11.8333 5.0026L0.75 9.66927ZM1.91667 7.91927L8.82917 5.0026L1.91667 2.08594V4.1276L5.41667 5.0026L1.91667 5.8776V7.91927Z"
									fill="white"
								/>
							</svg>
						)
					}
					sx={{
						borderRadius: '41px',
						letterSpacing: '-0.25px',
						fontWeight: 500,
						fontSize: '12px',
						lineHeight: '14px',
						color: '#fff',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						whiteSpace: 'nowrap',
						padding: '5px 13px',
						minWidth: '120px',
					}}
					variant="contained"
					onClick={handleClick}
				>
					{isLoading ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : t('Resend Invite')}
				</Button>
			</CustomTooltip>
		</Box>
	);
};

const CustomTimelineDot = styled(TimelineDot)(({ active, isErrored }) => ({
	background: isErrored ? '#B96877' : active ? '#03DAC5' : '#5c5c5c',
	position: 'relative',
	height: '10px',
	width: '10px',

	'&::after': {
		content: '""',
		display: active ? 'block' : 'none',
		position: 'absolute',
		top: '7px',
		right: 0,
		bottom: 0,
		left: '7px',
		background: '#fff',
		width: '4px',
		height: '4px',
		borderRadius: '4px',
	},
}));

const CustomTimelineConnector = styled(TimelineConnector)(({ active, isErrored }) => ({
	background: isErrored ? '#B96877' : active ? '#03DAC5' : '#5c5c5c',
	width: '4px',
}));

export default ResendInviteButton;
