import React, { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import { ReactComponent as BlumeLogo } from '@worklist-2/ui/src/assets/icons/blume_logo.svg';

import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import fhirExtensionUrls from '@rs-core/fhir/extension/fhirExtensionUrls';
import ResendInviteButton from '@rs-ui/views/PatientInformationView/components/ResendInviteButton';
import { useToast } from '@worklist-2/core/src/hooks/useToast';
import Toast from '@worklist-2/ui/src/components/Toast';

import lodashFind from 'lodash/find';

const BLUME_INVITE_STATUS = {
	ACTIVE: {
		value: 'ACTIVE',
		apiLabel: 'Active',
	},
	SENT: {
		value: 'INVITE SENT',
		apiLabel: 'Invite Sent',
	},
	ERROR: {
		value: 'ERROR',
		apiLabel: 'Invite Error',
	},
};

const BlumeInviteStatus = ({ isHovered }) => {
	const patient = usePatientStore(state => state.patient?.patient);
	const [showResend, setShowResend] = useState(false);

	const { setToastUtility, toastOpen, toastMessage, handleToastClose } = useToast();

	const isBlumePatient = useMemo(
		() => lodashFind(patient.extension, { url: fhirExtensionUrls.patient.blumePatient })?.valueBoolean,
		[patient]
	);

	const inviteStatus = useMemo(() => {
		const status = lodashFind(patient.extension, { url: fhirExtensionUrls.patient.inviteStatus })?.valueString;

		if (status === BLUME_INVITE_STATUS.ACTIVE.apiLabel || isBlumePatient) {
			return BLUME_INVITE_STATUS.ACTIVE.value;
		}

		if (status === BLUME_INVITE_STATUS.SENT.apiLabel) {
			return BLUME_INVITE_STATUS.SENT.value;
		}

		if (status === BLUME_INVITE_STATUS.ERROR.apiLabel) {
			return BLUME_INVITE_STATUS.ERROR.value;
		}

		return 'UNKNOWN STATUS';
	}, [patient]);

	const backgroundColor = useMemo(() => {
		if (inviteStatus === BLUME_INVITE_STATUS.ACTIVE.value || isBlumePatient) {
			return 'rgba(114, 217, 180, 0.2)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.SENT.value) {
			return 'rgba(188, 188, 188, 0.2)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.ERROR.value) {
			return 'rgba(185, 104, 119, 0.2)';
		}

		return 'rgba(185, 104, 119, 0.2)';
	}, [inviteStatus]);

	const color = useMemo(() => {
		if (inviteStatus === BLUME_INVITE_STATUS.ACTIVE.value || isBlumePatient) {
			return 'rgba(114, 217, 180, 1)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.SENT.value) {
			return 'rgba(188, 188, 188, 1)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.ERROR.value) {
			return 'rgba(185, 104, 119, 1)';
		}

		return 'rgba(185, 104, 119, 1)';
	}, [inviteStatus]);

	const borderColor = useMemo(() => {
		if (inviteStatus === BLUME_INVITE_STATUS.ACTIVE.value || isBlumePatient) {
			return 'rgba(255, 255, 255, 0.1)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.SENT.value) {
			return 'rgba(255, 255, 255, 0.1)';
		}

		if (inviteStatus === BLUME_INVITE_STATUS.ERROR.value) {
			return 'rgba(255, 255, 255, 0.1)';
		}

		return 'rgba(255, 255, 255, 0.1)';
	}, [inviteStatus]);

	const closeResentBtn = useCallback(() => {
		setShowResend(false);
	}, []);

	const showToastMessage = useCallback(
		msg => {
			setToastUtility(true, msg);
		},
		[setToastUtility]
	);

	return (
		<>
			<Toast message={toastMessage} open={toastOpen} onClose={handleToastClose} />
			{showResend ? (
				<ResendInviteButton closeResentBtn={closeResentBtn} showToastMessage={showToastMessage} />
			) : (
				<Box
					sx={{
						borderRadius: '41px',
						letterSpacing: '0.25px',
						padding: isHovered ? '5px' : '5px 8px',
						boxSizing: 'border-box',
						fontWeight: 500,
						fontSize: '12px',
						lineHeight: '14px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '4px',
						height: '24px !important',
						width: isHovered ? '24px !important' : 'unset',
						border: `1px solid ${borderColor}`,
						whiteSpace: 'nowrap',
						cursor: 'pointer',
						backgroundColor,
						color,
					}}
					variant="contained"
					onClick={() => setShowResend(true)}
				>
					<Icon
						component={BlumeLogo}
						style={{
							height: '12px',
							width: '12px',
						}}
					/>
					{!isHovered && inviteStatus}
				</Box>
			)}
		</>
	);
};

export default BlumeInviteStatus;
