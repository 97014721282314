import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Box, ClickAwayListener, IconButton, InputBase, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import LogoIcon from '@worklist-2/ui/src/assets/icons/omegaAI_logo.svg';
import CustomButton from './common/CustomButton';
import HeaderButtons from './common/HeaderButtons';
import ScriptSection from './common/ScriptSection';
import MapData from './MapData';
import Testing from './Testing';
import { useConfig } from '@worklist-2/core/src';
import getColumnMapping from '@rs-core/fhir/resource/columnMapping/getColumnMapping';
import { MESSAGE_TYPES } from './AddStationStep';

const convertToMap = (data, type) => {
	if (!data) {
		return [];
	}

	return data.Mappings.map(item =>
		type === 'Inbound' ? { columnName: item.To, value: item.From } : { columnName: item.From, value: item.To }
	);
};

const convertToSave = (data, messageType, HL7Type) => {
	const HL7 = HL7Type.split('_');

	return {
		Mappings: data.map(item =>
			messageType === 'Inbound'
				? { To: item.columnName, From: item.value }
				: { From: item.columnName, To: item.value }
		),
		MessageType: HL7[0],
		MessageEvent: HL7[1],
	};
};

const InboundMapping = ({
	accountData,
	messageType,
	changePrevStep,
	onClose,
	initData,
	updateData,
	selectHL7MessageType,
	setSelectHL7MessageType,
	deviceID,
	showScriptSaveBtn,
}) => {
	const [isCodeEditorOpen, setIsCodeEditorOpen] = useState(false);
	const [isInputShow, setIsInputShow] = useState(false);
	const [isTestRun, setIsTestRun] = useState(false);
	const [draggingItem, setDraggingItem] = useState(null);
	const [code, setCode] = useState('(function(fhirResources){});');
	const [script, setScript] = useState({
		InboundScript: [],
		OutboundScript: [],
	});
	const [isLoadingCode, setIsLoadingCode] = useState(false);
	const [state, setState] = useState(convertToMap(initData, messageType));
	const [isPopoverOpen, setIsPopoverOpen] = useState(null);
	const [initScript, setInitScript] = useState(null);

	const __config = useConfig();

	useEffect(() => {
		setState(convertToMap(initData, messageType));
	}, [initData, selectHL7MessageType]);

	useEffect(() => {
		if (isCodeEditorOpen) {
			getHL7Scripting();
		}
	}, [isCodeEditorOpen]);

	const getHL7Scripting = async () => {
		setIsLoadingCode(true);
		const { data } = await axios
			.get(`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${deviceID}/hl7scripting`)
			.catch(console.error);

		const type = messageType === 'Inbound' ? 'InboundScript' : 'OutboundScript';

		if (data) {
			setScript(JSON.parse(data));
			if (JSON.parse(data)[type]) {
				const tempScript = JSON.parse(data)[type].find(
					item => `${item.MessageType}_${item.MessageEvent}` === selectHL7MessageType
				);
				setInitScript(tempScript);
				if (tempScript) {
					setCode(tempScript.Script);
				}
			}
		}
		setIsLoadingCode(false);
	};

	const sendHL7Scripting = async newCode => {
		let isSuccess = false;
		const HL7 = selectHL7MessageType.split('_');
		const newScript = {
			Script: newCode,
			MessageType: HL7[0],
			MessageEvent: HL7[1],
		};
		const type = messageType === 'Inbound' ? 'InboundScript' : 'OutboundScript';
		const tempScript = { ...script };
		if (!tempScript[type]) {
			tempScript[type] = [];
		}
		if (initScript) {
			tempScript[type] = tempScript[type].map(item =>
				item.MessageType === newScript.MessageType && item.MessageEvent === newScript.MessageEvent
					? newScript
					: item
			);
		} else {
			tempScript[type].push(newScript);
		}
		const payload = JSON.stringify(tempScript);

		await axios
			.put(
				`${__config.data_sources.breeze}/DeviceOmegaAI/${accountData?.AccountId}/${deviceID}/hl7scripting`,
				payload,
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then(res => {
				if (res.status === 200) {
					setInitScript(newScript);
					isSuccess = true;
				}
			})
			.catch(console.error);

		return isSuccess;
	};

	const handleItemCreate = columnName => {
		state.unshift({ columnName, value: null });
		setState(state);
	};

	const handleItemEdit = (columnName, droppedItem) => {
		setState(state.map(item => (item.columnName === columnName ? { ...item, value: droppedItem.value } : item)));
	};

	const handleSubmit = (withClose = true) => {
		if (state.length !== 0) {
			const newData = convertToSave(state, messageType, selectHL7MessageType);
			updateData(newData, !!initData);
		}

		if (withClose) {
			changePrevStep();
		}
	};

	const handlePopoverToggle = () => {
		setIsPopoverOpen(prev => !prev);
	};

	return (
		<>
			<Box
				sx={{
					boxSizing: 'border-box',
					position: messageType === 'Inbound' ? 'relative' : 'fixed',
					right: messageType === 'Inbound' ? 'unset' : '538px',
					zIndex: '20',
					width: '538px',
					height: '100vh',
					padding: '17px 24px 10px',
					display: 'flex',
					flexDirection: 'column',
					background: '#FFF',
					boxShadow: messageType === 'Inbound' ? 'none' : '-4px 0 20px 0 rgba(33,88,119,0.1)',
				}}
			>
				{isTestRun ? (
					<Testing code={code} setIsTestRun={setIsTestRun} />
				) : (
					<>
						<Box
							sx={{
								marginBottom: '17px',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<IconButton
									data-testid="changePrevStepButton"
									sx={{ padding: 0 }}
									onClick={changePrevStep}
								>
									<ArrowBackIcon />
								</IconButton>
								<Typography
									sx={{
										marginLeft: '12px',
										fontFamily: 'Inter',
										fontWeight: 500,
										fontSize: '18px',
										color: 'rgba(0, 0, 0, 0.9)',
									}}
								>
									{messageType} Mapping
								</Typography>
							</Box>
							<IconButton data-testid="closeButton" sx={{ padding: 0 }} onClick={onClose}>
								<LastPageIcon />
							</IconButton>
						</Box>

						{isCodeEditorOpen ? (
							<ScriptSection
								code={code}
								disabled={isLoadingCode}
								isCodeEditorOpen={isCodeEditorOpen}
								isTestRun={isTestRun}
								sendHL7Scripting={sendHL7Scripting}
								setCode={setCode}
								setIsCodeEditorOpen={setIsCodeEditorOpen}
								setIsTestRun={setIsTestRun}
								showSave={showScriptSaveBtn}
							/>
						) : (
							<>
								<Box
									sx={{
										boxSizing: 'border-box',
										width: '100%',
										border: '1px solid rgba(196, 196, 196, 0.5)',
										borderRadius: '10px',
										flex: 1,
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Box
										sx={{
											boxSizing: 'border-box',
											paddingLeft: '22px',
											paddingRight: '10px',
											height: '48px',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											borderBottom: '1px solid rgba(196, 196, 196, 0.5)',
										}}
									>
										<Box sx={{ position: 'relative' }}>
											<Box
												sx={{
													padding: '4px 12px',
													display: 'flex',
													alignItems: 'center',
													border: '1px solid transparent',
													cursor: 'pointer',
													'&:hover': {
														border: '1px solid rgba(196, 196, 196, 0.5)',
														borderRadius: '10px',
														backgroundColor: '#FBFBFB',
													},
												}}
												onClick={handlePopoverToggle}
											>
												<Box
													sx={{
														width: '14px',
														height: '14px',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														backgroundColor: '#42A5F5',
														borderRadius: '50%',
														backgroundClip: 'padding-box',
														border: `1px solid rgba(66, 165, 245, 0.19)`,
													}}
												>
													<LogoIcon />
												</Box>
												<Typography
													sx={{
														marginLeft: '8px',
														fontFamily: 'Inter',
														fontWeight: 500,
														fontSize: '14px',
														color: 'rgba(0, 0, 0, 0.6)',
													}}
												>
													{selectHL7MessageType}
												</Typography>
											</Box>
											<InboundMappingPopover
												handleSubmit={handleSubmit}
												isOpen={isPopoverOpen}
												setSelectHL7MessageType={setSelectHL7MessageType}
												onClose={handlePopoverToggle}
											/>
										</Box>
										{state.length > 0 && (
											<HeaderButtons
												isCodeEditorOpen={isCodeEditorOpen}
												setIsCodeEditorOpen={setIsCodeEditorOpen}
											/>
										)}
									</Box>

									<Box sx={{ padding: '16px 24px', flex: 1, overflowY: 'auto' }}>
										<IconButton
											data-testid="addButton"
											sx={{
												width: '100%',
												border: '1px dashed #C4C4C4',
												borderRadius: '4px',
												marginBottom: '16px',
											}}
											onClick={() => setIsInputShow(true)}
										>
											<AddIcon sx={{ color: '#C4C4C4' }} />
										</IconButton>
										{isInputShow && (
											<InboundMappingInput
												handleInputClose={() => setIsInputShow(false)}
												handleItemCreate={handleItemCreate}
												selectedColumn={
													state.length > 0 ? state.map(item => item.columnName) : []
												}
											/>
										)}
										<Box
											sx={{
												height: isInputShow ? 'calc(100vh - 330px)' : 'calc(100vh - 270px)',
												overflowY: 'auto',
											}}
										>
											{state.map(item => (
												<InboundMappingItem
													key={item.columnName}
													draggingItem={draggingItem}
													handleItemEdit={handleItemEdit}
													title={item.columnName}
													value={item.value}
												/>
											))}
										</Box>
									</Box>
								</Box>
								{state.length > 0 && (
									<Box
										sx={{
											padding: '14px 6px 6px',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<CustomButton outlined onClick={changePrevStep}>
											go back
										</CustomButton>
										<CustomButton onClick={handleSubmit}>done</CustomButton>
									</Box>
								)}
							</>
						)}
					</>
				)}
			</Box>
			<MapData
				isSplit={isCodeEditorOpen}
				messageType={messageType}
				selectHL7MessageType={selectHL7MessageType}
				selectedItems={state}
				setDraggingItem={setDraggingItem}
			/>
		</>
	);
};

const InboundMappingInput = ({ handleItemCreate, handleInputClose, selectedColumn }) => {
	const [inputValue, setInputValue] = useState('');
	const [isSelected, setIsSelected] = useState(false);
	const [columnName, setColumnName] = useState('');

	const rawColumnNameList = getColumnMapping('default');
	const columnNameList = _.map(rawColumnNameList, item => item.label).filter(
		label => label && !selectedColumn.includes(label) && label.toLowerCase().includes(inputValue.toLowerCase())
	);

	const handleConfirmClick = () => {
		handleItemCreate(columnName);
		handleInputClose();
	};

	const handleColumnSelect = name => {
		setInputValue(name);
		setColumnName(name);
	};

	const handleInputChange = event => {
		if (columnName) {
			setColumnName('');
		}

		setInputValue(event.target.value);
	};

	return (
		<Box sx={{ marginBottom: '16px', width: '100%', display: 'flex', alignItems: 'center' }}>
			<Box sx={{ position: 'relative', width: '100%' }}>
				<InputBase
					inputProps={{
						'data-testid': 'mappingInput',
					}}
					placeholder="Search"
					startAdornment={
						<SearchIcon
							sx={{
								fontSize: '20px',
								color: isSelected || columnName ? '#4D79EA' : 'rgba(196, 196, 196, 0.6)',
							}}
						/>
					}
					sx={{
						padding: '12px',
						width: '100%',
						border: isSelected || columnName ? '1px solid #4D79EA' : '1px solid rgba(196, 196, 196, 0.6)',
						borderRadius: '10px',
						input: {
							padding: '0 0 0 8px',
							fontSize: '14px',
							color: 'rgba(0, 0, 0, 0.6)',
							'&::placeholder': {
								color: '#C4C4C4',
								opacity: '0.6',
								textTransform: 'uppercase',
								fontSize: '12px',
								fontStyle: 'italic',
							},
						},
					}}
					value={inputValue}
					onChange={e => handleInputChange(e)}
					onFocus={() => {
						setIsSelected(true);
					}}
				/>
				{isSelected && !columnName && (
					<Box
						sx={{
							zIndex: 1,
							position: 'absolute',
							top: '50px',
							left: 0,
							boxSizing: 'border-box',
							width: '100%',
							maxHeight: '192px',
							padding: '10px',
							backgroundColor: '#FFFFFF',
							border: '1px solid rgba(196, 196, 196, 0.4)',
							borderRadius: '10px',
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
							overflowY: 'auto',
						}}
					>
						{columnNameList.map(label => (
							<Typography
								data-testid="columnName"
								sx={{
									padding: '2px 28px',
									fontFamily: 'Inter',
									fontWeight: 500,
									fontSize: '14px',
									color: 'rgba(0, 0, 0, 0.6)',
									cursor: 'pointer',
								}}
								onClick={() => handleColumnSelect(label)}
							>
								{label}
							</Typography>
						))}
					</Box>
				)}
			</Box>
			{columnName && (
				<IconButton
					data-testid="createButton"
					sx={{
						margin: '0 4px',
						padding: 0,
					}}
					onClick={handleConfirmClick}
				>
					<CheckCircleIcon sx={{ color: '#4D79EA' }} />
				</IconButton>
			)}
			<IconButton
				sx={{
					marginLeft: '6px',
					padding: 0,
				}}
				onClick={handleInputClose}
			>
				<DeleteIcon sx={{ color: '#4D79EA' }} />
			</IconButton>
		</Box>
	);
};

const InboundMappingItem = ({ title, value, handleItemEdit, draggingItem }) => {
	const [isDragEnter, setIsDragEnter] = useState(false);

	const handleDragOver = e => {
		e.preventDefault();
		if (!value) {
			setIsDragEnter(true);
		}
	};

	const handleDragLeave = () => {
		if (!value) {
			setIsDragEnter(false);
		}
	};

	const handleDrop = () => {
		if (!value) {
			handleItemEdit(title, draggingItem);
			setIsDragEnter(false);
		}
	};

	return (
		<Box
			sx={{
				marginBottom: '6px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				'&:last-child': { margin: 0 },
			}}
		>
			<Box sx={{ marginLeft: '6px', display: 'flex', alignItems: 'center' }}>
				<Box
					sx={{
						flexShrink: '0',
						width: '18px',
						height: '18px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#42A5F5',
						borderRadius: '50%',
						backgroundClip: 'padding-box',
						border: '1px solid rgba(66, 165, 245, 0.19)',
					}}
				>
					<LogoIcon />
				</Box>
				<Typography
					sx={{
						marginLeft: '6px',
						fontFamily: 'Inter',
						fontWeight: 500,
						fontSize: '14px',
						color: 'rgba(0, 0, 0, 0.6)',
					}}
				>
					{title}
				</Typography>
			</Box>
			<Box
				sx={{
					flexShrink: '0',
					boxSizing: 'border-box',
					width: '275px',
					padding: value ? '7px 14px' : '12px 23px',
					backgroundColor: isDragEnter ? 'rgba(77, 121, 234, 0.05)' : '#FBFBFB',
					border: isDragEnter ? '1px solid #4D79EA' : '1px solid #C4C4C4',
					borderRadius: '10px',
				}}
				onDragLeave={handleDragLeave}
				onDragOver={e => handleDragOver(e)}
				onDrop={handleDrop}
			>
				{value ? (
					<Box
						sx={{
							padding: '6px',
							width: 'fit-content',
							display: 'flex',
							alignItems: 'center',
							backgroundColor: '#4D79EA',
							borderRadius: '5px',
						}}
					>
						<Typography
							sx={{
								marginRight: '24px',
								fontFamily: 'Inter',
								fontWeight: 600,
								fontSize: '14px',
								lineHeight: '16px',
								color: '#FFFFFF',
							}}
						>
							{value}
						</Typography>
						<IconButton sx={{ padding: 0 }} onClick={() => handleItemEdit(title, { value: null })}>
							<CloseIcon sx={{ color: '#FFFFFF', fontSize: '16px' }} />
						</IconButton>
					</Box>
				) : (
					<Typography
						sx={{
							fontFamily: 'Inter',
							fontStyle: 'italic',
							fontWeight: 500,
							fontSize: '12px',
							textTransform: 'uppercase',
							color: 'rgba(57, 57, 57,0.5)',
						}}
					>
						Drag data here
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const InboundMappingPopover = ({ isOpen, onClose, setSelectHL7MessageType, handleSubmit }) => {
	const handleItemClick = item => {
		handleSubmit(false);
		setSelectHL7MessageType(item);
		onClose();
	};

	return isOpen ? (
		<ClickAwayListener onClickAway={onClose}>
			<Box
				sx={{
					zIndex: 1,
					position: 'absolute',
					top: '0',
					left: '0',
					width: '160px',
					maxHeight: '232px',
					display: 'flex',
					flexDirection: 'column',
					border: '1px solid rgba(196, 196, 196, 0.5)',
					borderRadius: '8px',
					background: '#FFF',
					overflowY: 'auto',
				}}
			>
				{MESSAGE_TYPES.map(key => (
					<Box
						key={key}
						sx={{
							padding: '4px 12px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: '#FBFBFB',
							},
						}}
						onClick={() => handleItemClick(key)}
					>
						<Box
							sx={{
								width: '14px',
								height: '14px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#42A5F5',
								borderRadius: '50%',
								backgroundClip: 'padding-box',
								border: `1px solid rgba(66, 165, 245, 0.19)`,
							}}
						>
							<LogoIcon />
						</Box>
						<Typography
							sx={{
								marginLeft: '8px',
								fontFamily: 'Inter',
								fontWeight: 500,
								fontSize: '14px',
								color: 'rgba(0, 0, 0, 0.6)',
							}}
						>
							{key}
						</Typography>
					</Box>
				))}
			</Box>
		</ClickAwayListener>
	) : null;
};

export default InboundMapping;
