import { useState } from 'react';

export const useModal = () => {
	const [modal, setModal] = useState(null);

	return {
		modal,
		openModal: setModal,
		closeModal: () => {
			setModal(null);
			//reverting transparent background change for terms of service and privacy policy
			const element = document.getElementsByTagName('body')[0];
			element.style.backgroundColor = '';
		},
	};
};
