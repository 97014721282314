import { fhirExtensionUrls } from '@worklist-2/core/src';

export const getDevicesWithStations = data => {
	const dicomEntity = data.filter(item => item.resource.type.coding[0].code === 'dicom-entity');
	const entityMap = {};

	dicomEntity.map(item => {
		const relation = item.resource.extension.find(elem => elem.url === fhirExtensionUrls.device.associatedDevice);
		const key = relation?.valueReference.id;

		if (entityMap[`${key}`] === undefined) entityMap[`${key}`] = [];
		entityMap[`${key}`].push(item);
	});

	const filteredDevices = data
		.filter(
			elem =>
				elem.resource.type.coding[0].code === 'OmegaAILink' || elem.resource.type.coding[0].code === 'dicom-web'
		)
		.map(item => item.resource);

	return [entityMap, filteredDevices];
};

export const getDeviceType = device => {
	switch (device.type?.coding[0].code) {
		case 'dicom-web':
			return 'DICOM Web';
		case 'OmegaAILink':
			return 'OmegaAI Link';
		case 'dicom-entity':
			return 'DICOM Station';
	}
};

export const licenseApplicationNameValues = ['Augnito', 'pVerify Eligibility', 'Prior Auth'];
